import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';

import { useIntl } from 'gatsby-plugin-intl';

import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';

import s from './PrivacyPolicy.module.scss';

interface IPrivacyPolicyTemplate {
  data: {
    wpPrivacyPolicy: null | {
      content: string;
    };
  }
}

const PrivacyPolicyTemplate: FC<IPrivacyPolicyTemplate> = (props) => {
  const {
    data: {
      wpPrivacyPolicy,
    },
  } = props;

  const intl = useIntl();

  let content = '';

  if (wpPrivacyPolicy !== null) {
    content = wpPrivacyPolicy.content;
  }

  return (
    <>
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale
        }}
        noindex={true}
        nofollow={true}
        canonical={`https://avionaut.com${getLink(intl.locale, PageType.PRIVACY_POLICY)}/`}
        title={`Avionaut - ${intl.formatMessage({ id: 'metaTitle__privacyPolicy' })}`}
        description={intl.formatMessage({ id: 'metaDescription__privacyPolicy' })}
        metaTags={[
          {
          property: 'og:title',
          content: intl.formatMessage({ id: 'metaTitle__privacyPolicy' })
          },
          {
            property: 'og:description',
            content: intl.formatMessage({ id: 'metaDescription__privacyPolicy' }),
          },
          {
            property: 'twitter:title',
            content: intl.formatMessage({ id: 'metaTitle__privacyPolicy' })
          },
          {
            property: 'twitter:description',
            content: intl.formatMessage({ id: 'metaDescription__privacyPolicy' })
          },
        ]}
      />
      <div className={s.privacyPolicy}>
        <div className={s.privacyPolicy__container} dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </>
  );
};

export const query = graphql`
  query GetPrivacyPolicy($locale: String) {
    wpPrivacyPolicy(locale: {locale: {eq: $locale}}, slug: {eq: "polityka-prywatnosci"}) {
      content
    }
  }
`;

export default PrivacyPolicyTemplate;
